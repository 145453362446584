<template>
  <!-- navbar  -->
  <div class="main-height bg-black">

<header>
    <div class="container-fluid">
        <div class="header">
            <div class="main-page-logo">
                <a class="header__logo" href="#">
                    <img src="../assets/logo.svg" alt="" />
                </a>
            </div>
            <a role="button" class="signin-btn" @click="this.$router.push('/signin')">Sign in</a>
        </div>
    </div>
</header>

<section class="banner__section" v-show="showEmail">
    <img class="banner" src="../assets/main-img.jpg" alt="" />
    <div class="banner__overlay"></div>
    <div class="banner__content">
        <h1 class="banner__title text-white">Unlimited Cricket matches, PSL, IPL, and more.</h1>
        <h2 class="banner__subtitle text-white">Watch anywhere, Anytime.</h2>

        <h4 class="form__cta text-white">
            Ready to watch? Enter your email to create or sign-in to your account.
        </h4>
        <div v-if="error.length" class="alert-danger mb-2 text-danger email-error">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                class="bi bi-exclamation-circle me-1 mb-1" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                <path
                    d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
            </svg>
            {{ error }}
        </div>
        <div class="email-form" >
          <div class="email-form-lockup">
              <div class="register-form-main">
                  <div class="input-group">
                      <input required="" type="text" name="email"  class="input" v-model="email" v-on:keyup.enter="doSubmitEmail">
                      <label class="user-label">Email address</label>
                  </div>
              </div>
              <div class="d-flex justify-content-center">
                <button class="button--submit" @click="doSubmitEmail">
                    <span v-show="!loading">
                        Get Started
                        <span>
                            <svg class="mb-1 bi bi-chevron-right" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                fill="currentColor" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                            </svg>
                        </span>
                    </span>
                    <div v-show="loading" class="button-loading">
                        <div class="spinner-border" role="status">
                            <span class="sr-only"></span>
                        </div>
                    </div>
                </button>
              </div>
          </div>
          <div class="center-pixel"></div>
      </div>
    </div>
</section>

<section class="watch-section bg-black story-card">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12 my-auto">
                <div class="text-section">
                    <h1 class="text-white">Watch everywhere.</h1>
                    <h2 class="text-white">Stream unlimited Cricket matches on your phone, tablet, laptop, and TV.
                    </h2>
                </div>
            </div>
            <div class="col-lg-4 col-md-12 my-auto">
                <div class="img-section">
                    <img width="100%" src="../assets/three-screens.webp" alt="">
                </div>
            </div>
        </div>
    </div>
</section>

<section class="faq-section bg-black story-card">
    <div class="faq-container">
        <div class="faq">
            <h1 class="text-white fw-bold">Frequently Asked Questions</h1>
            <ul class="accordion__list">
                <li class="accordion" @click="dotoggled">
                    <button class="accordion__question">
                        What is ProCricket?
                    </button>
                    <p class="accordion__answer">
                        ProCricket is a streaming service that offers all the top-tier cricket from around the world in high definition. You can
                        watch as much as you want, whenever you want - all for one low yearly price.
                    </p>
                </li>
                <li class="accordion" @click="dotoggled">
                    <button class="accordion__question">
                        What can I watch on ProCricket?
                    </button>
                    <p class="accordion__answer">
                        ProCricket offers you to watch all the top-tier cricket, including all International One-day, Twenty20, Test
                        series and
                        World Cups, IPL, PSL, and BBL.
                    </p>
                </li>
                <li class="accordion" @click="dotoggled">
                    <button class="accordion__question">
                        Do you offer a trial?
                    </button>
                    <p class="accordion__answer">
                      Yes, we do! Experience ProCricket with our unlimited trial, offering 10 minutes of streaming daily.
                      Explore all our features during this period. To begin, simply sign up with a valid email—no credit card information needed.
                    </p>
                </li>
                <li class="accordion" @click="dotoggled">
                    <button class="accordion__question">
                        How much does ProCricket cost?
                    </button>
                    <p class="accordion__answer">
                        You can watch ProCricket on your smartphone, tablet, or laptop, all for one fixed yearly fee. Plans range from $40 to
                        $80 a year: no extra costs, no contracts, and no auto-renewals.
                    </p>
                </li>
                <li class="accordion" @click="dotoggled">
                    <button class="accordion__question">
                        What platforms and devices do you currently support?
                    </button>
                    <p class="accordion__answer">
                        You can enjoy ProCricket on any laptop, desktop, PC, Mac, smartphone, or tablet via an up-to-date web browser
                        and
                        operating system. Native apps for Android phones and Android TVs will be available soon.
                    </p>
                </li>

            </ul>
            <div class="faq-email" action="">
                <h3>
                    Ready to watch? Enter your email to create or sign-in to your account.
                </h3>
                <div v-if="error.length" class="alert-danger mb-3 text-danger email-error">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-exclamation-circle me-1 mb-1" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                        <path
                            d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                    </svg>
                    {{ error }}
                </div>
                <div class="email-form">
                  <div class="email-form-lockup">
                      <div class="register-form-main">
                          <div class="input-group">
                              <input required="" type="text" name="email"  class="input" v-model="email" @keyup.enter="doSubmitEmail">
                              <label class="user-label">Email address</label>
                          </div>
                      </div>

                      <div class="d-flex justify-content-center">
                          <button class="button--submit" @click="doSubmitEmail" >
                              <span v-show="!loading">
                                Get Started
                                <span>
                                    <svg class="mb-1 bi bi-chevron-right" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                        viewBox="0 0 16 16">
                                        <path fill-rule="evenodd"
                                            d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                                    </svg>
                                </span>
                              </span>
                            <div v-show="loading" class="button-loading">
                                <div class="spinner-border" role="status">
                                    <span class="sr-only"></span>
                                </div>
                            </div>
                          </button>
                      </div>
                  </div>
                  <div class="center-pixel"></div>
                </div>
            </div>
        </div>
    </div>
</section>
</div>

<Footer class="bg-black"></Footer>


</template>

<script>
import {defineComponent} from 'vue'

import Footer from "@/components/Footer.vue";
import UsersService from '@/services/users-service'

export default defineComponent({
name: 'Welcome',
components: {
  Footer
},
data() {
  return {
    showEmail: true,
    email: '',
    password: '',
    error: '',
    checked: '',
    loading: false
  }
},
methods: {

  validateEmail(email) {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  },
  doSubmitEmail() {
    if (this.loading) return;
    this.loading = true;

    if (this.email === '') {
      this.error = 'Please enter email address';
      this.loading = false;
    } else if (!this.validateEmail(this.email)) {
      this.error = 'Email address is invalid';
      this.loading = false;
    } else {
      UsersService.validateUserEmailRequest(this.email).then((valid) => {
        if (valid) {
          this.error = ''
          localStorage.setItem('email', this.email)
          this.$router.push('/register')
        } else {
          localStorage.setItem('email', this.email)
          this.$router.push('/signin')
        }
      }).catch(() => {
        this.error = 'Email validation failed';
        this.loading = false;
      })
    }
  },
  dotoggled($event) {
    const parent = $event.target.parentElement;
    parent.classList.toggle("active");
  }
},
});
</script>

<style>
@import '../assets/style.css';
</style>
